<template>
  <div class="position-relative overflow-hidden" style="background-color:#fafcfe">
    <div class="container space-top-2">
      <div class="text-center">
        <h1 class="mb-4">{{ $t('APIPortalPage.HeroSection.Title') }}</h1>
        <p class="lead mb-4">{{ $t('APIPortalPage.HeroSection.Description') }}</p>
        <a id="" href="https://hub.openapihub.com/login" class="btn btn-primary" target="_blank">{{ $t('APIPortalPage.HeroSection.StartButton') }}</a>
      </div>
    </div>
    <div class="position-relative mt-3 mt-md-0">
      <img class="img-fluid" src="@/assets/svg/illustrations/portal-generator-hero.svg" alt="OpenAPIHub Developer Portal">
    </div>
  </div>

</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>

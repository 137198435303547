<template>
  <div id="more-features">
    <div class="container space-2">

      <div class="w-md-85 w-lg-65 text-center mx-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('APIPortalPage.MoreFeatureSection.Subtitle') }}</span>
        <h2>{{ $t('APIPortalPage.MoreFeatureSection.Title') }}</h2>
        <p>{{ $t('APIPortalPage.MoreFeatureSection.Description') }}</p>
      </div>

      <div class="row">
        <div v-for="(item, index) in Features" :key="index" class="col-md-4 mb-4">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}`)">
            </figure>
            <h3>{{ $t(item.title) }}</h3>
            <p>{{ $t(item.description) }}</p>
          </div>
        </div>
      </div>

      <!-- Book a Demo -->
      <div class="container space-2">
        <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
          <div class="card-body text-center py-5">
            <h2>{{ $t('PricingPage.BookDemo.Title') }}</h2>
            <p>{{ $t('PricingPage.BookDemo.Description') }}</p>
            <router-link
              :to="{ path: `/${$route.params.lang}/contact`}"
              class="btn btn-primary mt-3"
            >{{ $t('PricingPage.BookDemo.Button') }}
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreFeatures',
  data() {
    return {
      Features: [
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature1Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature1Description',
          icon: 'icon-12.svg'
        },
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature2Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature2Description',
          icon: 'icon-44.svg'
        },
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature3Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature3Description',
          icon: 'icon-36.svg'
        },
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature4Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature4Description',
          icon: 'icon-20.svg'
        },
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature5Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature5Description',
          icon: 'icon-25.svg'
        },
        {
          title: 'APIPortalPage.MoreFeatureSection.Feature6Title',
          description: 'APIPortalPage.MoreFeatureSection.Feature6Description',
          icon: 'icon-3.svg'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

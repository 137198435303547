<template>
  <div>
    <Hero />
    <HowItWorks />
    <Showcases />
    <CTA />
    <MoreFeatures />
    <BnCTA />
    <OahOverview class="space-top-2" />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
import Showcases from './components/showcases'
import CTA from './components/cta'
import MoreFeatures from './components/more-features'
import BnCTA from './components/bn-cta'
import OahOverview from '@/components/oah-overview'

export default {
  name: 'PortalGenerator',
  components: {
    Hero,
    HowItWorks,
    Showcases,
    CTA,
    MoreFeatures,
    BnCTA,
    OahOverview
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'API Developer Portal | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/api-portal' }
      ],
      meta: [
        { name: 'description', content: 'Generate API Developer Portal in minutes with OpenAPIHub Platform.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'API Portal Generator | OpenAPIHub' },
        { property: 'og:description', content: 'Generate API Developer Portal in minutes with OpenAPIHub Platform.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/portal-generator.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/portal-generator' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
